import { Link, graphql } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import * as Contentstyle from '../../components/content/content.module.scss'
import * as style from '../../components/album/album.module.scss'
import Content from '../../components/content/content'
import Img from "gatsby-image"
import React from 'react'

const DoseOfDistinction2LP = ( {data} ) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div className={style.albumDetails}>
        {modal ? (
          <Link to={closeTo}>
            <svg className={style.albumDetails__close} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.53553 0.464768C7.92606 0.855293 7.92606 1.48846 7.53553 1.87898L5.41421 4.0003L7.53553 6.12162C7.92606 6.51215 7.92606 7.14531 7.53553 7.53584C7.14501 7.92636 6.51184 7.92636 6.12132 7.53584L4 5.41452L1.87868 7.53584C1.48815 7.92636 0.854988 7.92636 0.464463 7.53584C0.0739387 7.14531 0.0739387 6.51215 0.464463 6.12162L2.58578 4.0003L0.464463 1.87898C0.073939 1.48846 0.0739387 0.855293 0.464463 0.464768C0.854988 0.0742437 1.48815 0.0742441 1.87868 0.464768L4 2.58609L6.12132 0.464768C6.51184 0.0742441 7.14501 0.0742437 7.53553 0.464768Z" />
            </svg>
          </Link>
        ) : (
          <div>
            {/* Not sure */}
          </div>
        )}

          <Content extraClass='content--modal'>
            <div className="column column--40">
              <div className="sticky">

            <Img className={style.albumDetails__cover} fluid={data.cover.childImageSharp.fluid} />

            <p className={style.albumDetails__credits}>
              <strong>Executive Producers:</strong> Specyal T & Alex Whitcombe<br/>
              <strong>Label:</strong> Blackstream Records<br/>
              <strong>Recorded At:</strong> Massive Sound Studios<br/>
              <strong>Production Provided By:</strong> Foley, Slept Productions & Specyal T Productions <br/>
              <strong>Mixed & Mastered By:</strong> Simon Bader

            </p>
            
            <div className={Contentstyle.subtitleLowercase}>Tracks</div>

            <ol className={style.albumDetails__tracks}>
              <li>Her Inception</li>
              <li>Almost Lover <a href="https://itunes.apple.com/us/album/almost-lover-single/id849721003">iTunes</a></li>
              <li>Batteries Ain't Included ft. Catherine Marie</li>
              <li>Paper Cuts</li>
              <li>End Of An Error ft. Tyse</li>
              <li>Don't Matter Me</li>
              <li>Already Done</li>
              <li>No Such Thing ft. Jayde Wisdom</li>
              <li>Through Window Pain</li>
              <li>Tuxedo Delicious</li>
              <li>Lyrical Courage</li>
              <li>Just Between Us</li>
            </ol>
              </div>

            </div>
            <div className="column column--60">
            <span className={Contentstyle.titleLowercase}>Track Synopsis</span>

              <p><strong>Her Inception</strong><br/>
              An auto-biographical re-introduction of the multi-faceted artist they call Specyal T. Performed primarily in third person.</p>
              <hr />

              <p><strong>Almost Lover</strong><br/>
              Two teenagers that meet at the beginning of high school develop feelings for each other, but a variety of obstacles and miscommunications keep them from moving their relationship to the next level. Based on true events from my time during high school.</p>
              <hr />

              <p><strong>Batteries Ain't Included</strong><br/>
              A middle aged, well to do high society couple, are living the high life with four successful children and successful careers to match! She is a socialite spending her days organizing and hosting some of the most extravagant parties their area has ever seen, while He is busy as a top defence attorney. On the outside everything looks perfect, but She has a secret that's about to be exposed by their youngest son!</p>
              <hr />

              <p><strong>Paper Cuts</strong><br/>
              In this industry although there are many who are truly there to help uplift your career and push you to the next level, there are also many sharks who's main purpose is to bleed you dry. Their sole interest revolves around their own personal advancement using you as their vehicle to make it happen. This song is about three of those type of people that I unfortunately got involved with at different points in my career.</p>
              <hr />

              <p><strong>End Of An Error</strong><br/>
              A couple that have been together for a few years, have recently realized it's not working anymore and no longer healthy. Wanting to finally end things so they can both move on, She initiates the conversation. Although at first He tries to fight it, by the end He finally admits the mature thing to do is to just let go.</p>
              <hr />

              <p><strong>Don't Matter Me</strong><br/>
              I'm always inspired by my relationship with my husband 'Mr. Specyal'. In every relationship there will be trying times, it's natural, healthy and needed for it to evolve. This song is based on our time together as a whole, which now spans ten years.</p>
              <hr />

              <p><strong>Already Done</strong><br/>
              Early one morning a woman gets a cryptic message from her best friend of many years and she quickly rushes over to her house. After arriving she instantly realizes that the situation is a lot more serious than even she had previously imagined. Now immersed in an obvious crime scene, she tries to persuade her friend to turn herself in. To which her friend nonchalantly tells her it's "Already Done!"</p>
              <hr />

              <p><strong>No Such Thing</strong><br/>
              My career, as most everyone's, has had it's fair share of roadblocks and setbacks. No matter the circumstances the thought of just walking away from it all is a non issue. I always have faith in myself!</p>
              <hr />

              <p><strong>Through Window Pain</strong><br/>
              A very shy and sheltered 14yr old girl develops a crush on a 17yr old male classmate who's also a bit of a loner. The two begin a physical relationship which leads to pregnancy. At first angry about her new "situation", she wishes her "problem" would just go away. The stress and worry about the reaction from her family, along with a few immature choices ultimately leads her to have a miscarriage. Already feeling alone and previously contemplating running away, she confronts her boyfriend one last time, who now wants nothing to do with her and then she quietly leaves town.</p>
              <hr />

              <p><strong>Tuxedo Delicious</strong><br/>
              Spotlighting men who are always well dressed, fresh and correct and all around attractive!</p>
              <hr />

              <p><strong>Lyrical Courage</strong><br/>
              A 16yr old boy debates whether or not to leave his childhood home for good after years of verbal and physical abuse from his father. After his mom passed away when he was 10, his Father, unable to deal with the loss, turned very dark and began drinking heavily. The abuse followed shortly thereafter. Unable to take it anymore, the boy writes down all his feelings in a letter and then leaves to never return.</p>
              <hr />

              <p><strong>Just Between Us</strong><br/>
              An unexpected late addition to the album. This song was written for and inspired by my daughter Jazzy and the rough journey we went through from her conception to her birth!</p> 

           </div>

          </Content>

      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default DoseOfDistinction2LP


export const query = graphql`
query {
  cover: file(relativePath: {eq: "st-store/albums/doseofdistinction2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
}
`